import { FieldValue } from 'app/modules/models/filedValue.type';
import { LegalNotice } from 'app/modules/models/legalNotice.type';
import { Company } from 'app/modules/models/company.type';
import { Customer } from 'app/modules/models/customer.type';
import {
  Benefit,
  BenefitProductArguments,
  BenefitProductCategories,
  BenefitProductInformation,
} from 'app/modules/models/benefit.type';
import { Bonus, DevisPdf, Section } from '../modules/models/devis.type';
import { StringDividerTwoPartsPipe } from '../modules/devis/devis.component';
import { DatePipe } from '@angular/common';
import { MandateDelegateDto } from 'app/modules/models/mandateDelegate.type';
import { JwtHelperService } from '@auth0/angular-jwt';
import CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';
import moment from 'moment';
import { FieldModel } from 'app/modules/models/field.type';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { OperationFields } from 'app/modules/models/operationFields.type';
import { DesignOffice } from 'app/modules/models/designOffice.type';
import { format, parse } from 'date-fns';
import { formatInTimeZone, format as tzFormat } from 'date-fns-tz';

export function formatNumbers(string): string {
  const num = parseFloat(string);
  const newText = num.toLocaleString();
  return newText;
}

export function createFile(data: string, filename: string): File {
  var arr = data.split(','),
    mime = 'application/pdf',
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function replaceVariablesByData(
  legalNotice: LegalNotice,
  customer: Customer,
  company: Company,
  datePipe: DatePipe,
  devis?: DevisPdf,
  mandateDelegate?: MandateDelegateDto
): LegalNotice {
  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll('cuadds-mention', '');

  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll(
    'Nom du particulier',
    customer?.lastName
  );
  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll(
    'Prénom du particulier',
    customer?.firstName
  );
  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll(
    'Raison sociale',
    customer?.socialReason
  );
  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll(
    'Nom du délégataire sélectionné',
    mandateDelegate?.delegate?.firstName + ' ' + mandateDelegate?.delegate?.lastName
  );
  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll(
    'SIREN du délégataire',
    mandateDelegate?.delegate?.siren
  );
  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll(
    'Nom du mandataire sélectionné',
    mandateDelegate?.mandate?.firstName + ' ' + mandateDelegate?.mandate?.lastName
  );
  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll(
    'SIREN du mandataire',
    mandateDelegate?.mandate?.siren
  );

  //depends on bonus

  let ceeMwh = 0;
  let primeCee = 0;
  let primeMpr = 0;
  devis?.sections.forEach(element => {
    if (element?.bonuses != null && element.bonuses.length > 0) {
      element.bonuses.forEach(bonus => {
        if (bonus.bonusType == 'CEE') {
          ceeMwh += bonus.amount;
          primeCee += bonus.total;
        }
        if (bonus.bonusType == 'MPR') {
          primeMpr += bonus.amount;
        }
      });
    }
  });

  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll(
    'Montant des primes MPR en €',
    primeMpr + ''
  );
  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll(
    'Montant des CEE en MWh cumac',
    ceeMwh + ''
  );
  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll(
    'Montant des primes CEE en €',
    primeCee + ''
  );

  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll(
    'Raison sociale délégataire',
    mandateDelegate?.delegate?.name
  );
  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll(
    'Raison sociale mandataire',
    mandateDelegate?.mandate?.socialRaison
  );

  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll(
    'Date de création du devis',
    datePipe.transform(devis?.createdOnUtc, 'dd/MM/yyyy')
  );
  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll(
    'Date de début des travaux',
    datePipe.transform(customer?.workDate, 'dd/MM/yyyy')
  );
  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll(
    '{{Date de fin des travaux}}',
    datePipe.transform(customer?.workDate, 'dd/MM/yyyy')
  );
  legalNotice.legalContentHtml = legalNotice.legalContentHtml.replaceAll(
    'Date de règlement avant le',
    datePipe.transform(customer?.workDate, 'dd/MM/yyyy')
  );

  return legalNotice;
}

export function downloadPdf(base64String, fileName): void {
  const nav = window.navigator as any;

  if (nav && nav.msSaveOrOpenBlob) {
    // download PDF in IE
    let byteChar = atob(base64String);
    let byteArray = new Array(byteChar.length);
    for (let i = 0; i < byteChar.length; i++) {
      byteArray[i] = byteChar.charCodeAt(i);
    }
    let uIntArray = new Uint8Array(byteArray);
    let blob = new Blob([uIntArray], { type: 'application/pdf' });
    nav.msSaveOrOpenBlob(blob, `${fileName}.pdf`);
  } else {
    // Download PDF in Chrome etc.
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${fileName}.pdf`;
    document.body.appendChild(link); // Append link to body for certain browsers
    link.click();
    document.body.removeChild(link); // Remove link after use
  }
}

export function downloadFile(base64String, fileName, contentType): void {
  const nav = window.navigator as any;

  if (nav && nav.msSaveOrOpenBlob) {
    // Download in IE
    let byteChar = atob(base64String);
    let byteArray = new Array(byteChar.length);
    for (let i = 0; i < byteChar.length; i++) {
      byteArray[i] = byteChar.charCodeAt(i);
    }
    let uIntArray = new Uint8Array(byteArray);
    let blob = new Blob([uIntArray], { type: contentType });
    nav.msSaveOrOpenBlob(blob, `${fileName}`);
  } else {
    // Download in Chrome etc.
    const source = `data:${contentType};base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${fileName}`;
    link.click();
  }
}

export function prepareDevisPDFLegalNotices(
  legalNotices: LegalNotice[],
  company: Company,
  customer: Customer,
  devisPdf: DevisPdf,
  stringDividerTwoParts: StringDividerTwoPartsPipe,
  datePipe: DatePipe,
  mandateDelegate: MandateDelegateDto
): void {
  legalNotices.forEach(legalNoticeItem => {
    legalNoticeItem = replaceVariablesByData(
      legalNoticeItem,
      customer,
      company,
      datePipe,
      devisPdf,
      mandateDelegate
    );
    if (legalNoticeItem.type === 'bottomPage') {
      devisPdf.bottomLegalNotice = legalNoticeItem;
    } else if (legalNoticeItem.type == 'generalCondition') {
      devisPdf.generalCondition = legalNoticeItem.legalContentHtml;
    } else if (legalNoticeItem.type === 'hasBillingDetails') {
      devisPdf.invoiceLegalNotice = legalNoticeItem;
    } else {
      devisPdf.legalNotices.push(legalNoticeItem);
    }
  });
}

export function prepareDevisPDFData(
  company: Company,
  devisPdf: DevisPdf,
  customer: Customer,
  datePipe: DatePipe
): void {
  devisPdf.company = company;
  devisPdf.company.logo = 'data:image/png;base64,' + devisPdf.company.logo;
  devisPdf.customer = customer;
  devisPdf.signature = 'Date et signature précédées de la mention “Bon pour accord“';
  devisPdf.customer.visitDate = datePipe.transform(devisPdf.customer.visitDate, 'dd/MM/yyyy');
  devisPdf.createdOnUtc = datePipe.transform(devisPdf.createdOnUtc, 'dd/MM/yyyy');
  (devisPdf.devisAvailibility = devisPdf.devisAvailibility), 'dd/MM/yyyy';
  devisPdf.totalHt = +(parseFloat(devisPdf.totalHt + '').toFixed(2) + '');
  devisPdf.subTotalTTC = +(parseFloat(devisPdf.subTotalTTC + '').toFixed(2) + '');
  devisPdf.bonusCEE = +(parseFloat(devisPdf.bonusCEE + '').toFixed(2) + '');
  devisPdf.bonusMPR = +(parseFloat(devisPdf.bonusMPR + '').toFixed(2) + '');
  devisPdf.totalTTC = +(parseFloat(devisPdf.totalTTC + '').toFixed(2) + '');
  devisPdf.tva = +(parseFloat(devisPdf.tva + '').toFixed(2) + '');
  devisPdf.reductionAmount = +(parseFloat(devisPdf.reductionAmount + '').toFixed(2) + '');

  devisPdf.sections.forEach((benefit: Benefit) => {
    benefit.tva = +(parseFloat(benefit.tva + '').toFixed(2) + '');
    benefit.quantity = +(parseFloat(benefit.quantity + '').toFixed(2) + '');
    benefit.unitHTPrice = +(parseFloat(benefit.unitHTPrice + '').toFixed(2) + '');
    benefit.ttcPrice = calculateTotalTTC(
      benefit.unitHTPrice,
      benefit.tva,
      benefit.quantity,
      benefit.ttcPrice
    );
    benefit.htPrice = +(parseFloat(benefit.htPrice + '').toFixed(2) + '');
    benefit.unitTTCPrice = calculateUnitTTC(benefit.unitHTPrice, benefit.tva, benefit.unitTTCPrice);
  });
}

export function convertBlobToBase64String(blob: Blob) {
  let base64String = new Promise<string>((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      let base64String: string = reader.result as string;
      resolve(base64String);
    };
  });
  return base64String;
}

export function computePrices(devis: DevisPdf): void {
  let hasCEE = false;
  let hasMPR = false;
  let bonusCEE = 0;
  let bonusMPR = 0;
  let subTotalTTC = 0;
  let tva = 0;
  let totalHt = 0;
  let bonus = 0;
  let reduction = 0;
  devis.sections.forEach(x => {
    // tva = tva + ((x.unitHTPrice * x.tva) / 100) * x.quantity;
    // totalHt = totalHt + x.unitHTPrice * x.quantity;
    if (x.bonuses) {
      x.bonuses.forEach(x => {
        if (x.bonusType == 'CEE') {
          hasCEE = true;
          bonusCEE = bonusCEE + x.total;
          bonus = bonus + x.total;
        } else {
          hasMPR = true;
          bonusMPR = bonusMPR + x.amount;
          bonus = bonus + x.amount;
        }
      });
    }
  });
  subTotalTTC = subTotalTTC + tva + totalHt;
  if (hasCEE) {
    devis.bonusCEE = bonusCEE;
  }

  if (hasMPR) {
    devis.bonusMPR = bonusMPR;
  }

  if (devis.reductionAmount > 0) {
    reduction = devis.reductionAmount;
  }

  devis.tva = tva;
  devis.totalHt = totalHt;
  devis.subTotalTTC = subTotalTTC;
  devis.totalTTC = subTotalTTC - bonus - reduction;
}

export function titleCaseWord(word: string) {
  if (!word) return word;
  return word[0].toUpperCase() + word.substring(1).toLowerCase();
}

export function isSuperAdmin() {
  const helper = new JwtHelperService();
  let myRawToken = localStorage.getItem('accessToken');

  if (myRawToken != null) {
    const decodedToken = helper.decodeToken(myRawToken);
    let role: string[] | string = decodedToken.role;
    if (role == undefined) {
      role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    }
    if (typeof role === 'string') {
      if (role === 'SuperAdmin') {
        return true;
      }
    } else {
      if ((role as string[]).some(x => x === 'SuperAdmin')) {
        return true;
      }
    }
  }
  return false;
}

export function isACEPUser() {
  let connectedUser = JSON.parse(localStorage.getItem('connected_user'));
  return connectedUser.onACEP === 'True';
}

export function isSicaUser() {
  let connectedUser = JSON.parse(localStorage.getItem('connected_user'));
  return connectedUser.onSICA === 'True';
}

export function isBothACEPSicaUser() {
  let connectedUser = JSON.parse(localStorage.getItem('connected_user'));
  return connectedUser.onACEP === 'True' && connectedUser.onSICA === 'True';
}

export function getPaymentMethods(paymentMethods: any): string {
  let paymentList = paymentMethods ? JSON.parse(paymentMethods) : '';
  if (paymentList != '') {
    let payementMethods = Object.keys(paymentList).filter(key => paymentList[key] === true);
    if (payementMethods.length > 0) {
      let paymentMethod = '';
      let paymentMethodsDictionary = {
        cheque: 'Chèque',
        cash: 'Espèces',
        bankTransfer: 'Virement bancaire',
        BankCard: 'Carte bancaire',
      };
      payementMethods.forEach(element => {
        paymentMethod += paymentMethodsDictionary[element] + ', ';
      });
      paymentMethod = paymentMethod.slice(0, -2);
      return paymentMethod;
    }
  }
  return '';
}

export function encrypt(value: string): string {
  var b64 = CryptoJS.AES.encrypt(value, environment.encryptionKey).toString();
  var e64 = CryptoJS.enc.Base64.parse(b64);
  var eHex = e64.toString(CryptoJS.enc.Hex);
  return eHex;
}

export function decrypt(value: string): string {
  var reb64 = CryptoJS.enc.Hex.parse(value);
  var bytes = reb64.toString(CryptoJS.enc.Base64);
  var decrypt = CryptoJS.AES.decrypt(bytes, environment.encryptionKey);
  var plain = decrypt.toString(CryptoJS.enc.Utf8);
  return plain;
}

export function convertToEnglishDate(value: string): string {
  let frenchDate = moment(value, 'YYYY-MM-DD'); // Assuming the input value is in 'YYYY-MM-DD' format

  // Check if the parsed date is valid
  if (!frenchDate.isValid()) {
    throw new Error('Invalid date format. Expected format: YYYY-MM-DD');
  }

  const date = new Date(frenchDate.year(), frenchDate.month(), frenchDate.date());

  let datePipe = new DatePipe('en-US');

  return datePipe.transform(date, 'yyyy-MM-dd');
}

export function toFormGroup(operations: OperationFields[], initiate: boolean) {
  const group: any = {};
  operations.forEach(field => {
    group[
      field.id +
        ';' +
        field.isCommon +
        ';' +
        field.isMpr +
        ';' +
        field.idMpr +
        ';' +
        field.nom.trim()
    ] = new FormControl(
      {
        value: !initiate
          ? field.typeData !== 'bool'
            ? ''
            : false
          : field.typeParametreId != null && field.typeData == 'int'
          ? +field.currentValue
          : field.typeData == 'bool'
          ? field.currentValue == 'true'
            ? true
            : false
          : field.currentValue || '',
        disabled: false,
      },
      Validators.required
    );
  });
  return new FormGroup(group);
}

export function calculateCostOfWork(benefitList: Benefit[]): number {
  let costOfWork = 0;
  let benefits =
    benefitList?.length > 0
      ? benefitList.filter(x => x.label !== 'Sous-Total' && x.isMprEligible)
      : [];
  costOfWork =
    benefits?.length > 0
      ? benefits
          .map(o => o.ttcPrice)
          .reduce((a, c) => {
            return a + c;
          }, 0)
      : 0;
  return costOfWork;
}

export function prepareBonusList(
  section: Section,
  amountCEE: number,
  priceCEE: number,
  amountMPR: number
): Bonus[] {
  let bonusList: Bonus[] = [];
  let totalCEE = priceCEE * amountCEE;
  if (amountCEE > 0) {
    let bonusItem: Bonus = {
      amount: +(amountCEE ? parseFloat(amountCEE + '').toFixed(2) : 0),
      bonusType: 'CEE',
      total: +(totalCEE ? parseFloat(totalCEE + '').toFixed(2) : 0),
      price: +(totalCEE ? parseFloat(priceCEE + '').toFixed(2) : 0),
    };
    bonusList.push(bonusItem);
  }
  if (section.operation.hasMPR && amountMPR > 0) {
    let bonusItem: Bonus = {
      amount: +(amountMPR ? parseFloat(amountMPR + '').toFixed(2) : 0),
      bonusType: 'MPR',
    };
    bonusList.push(bonusItem);
  }
  return bonusList;
}

export function extractFields(mpr: boolean, section, currentDraftValue) {
  let invisibleFieldArray = [
    'ENERGIE_CHAUFFAGE',
    'C_MPR',
    'TYPE_MENAGE_CDP',
    'DEPARTEMENT',
    'Cout_Travaux_MPR',
    'Batiment_2ans',
    'SurfaceHabitable',
    'Energie_Chauffage_ref_9A',
    'Cout_travaux',
  ];
  //surfaceIsolant
  let fieldArrayCEE: FieldModel[] = [];
  let fieldArrayMPR: FieldModel[] = [];
  let sectionBenefits = [];
  if (section?.benefits?.length > 0) {
    sectionBenefits = section.benefits.filter(x => x.label !== 'Sous-Total');
  }
  let formField: FormGroup = new FormGroup({});
  let operationFields = currentDraftValue.operations.filter(x => x.affichage);
  formField = toFormGroup(operationFields, true);
  //extract fields for validation comparaison
  for (const [key, value] of Object.entries(formField.getRawValue())) {
    var currentValueToComputeBonus = Array.isArray(value) ? value['id'] : value;

    var splitKey = key.split(';');
    if (splitKey[1] == 'true' || splitKey[2] == 'true') {
      fieldArrayMPR.push({ baliseId: splitKey[0], valeur: currentValueToComputeBonus + '' });
    }
    if (splitKey[2] == 'false') {
      fieldArrayCEE.push({ baliseId: splitKey[0], valeur: currentValueToComputeBonus + '' });
    }
  }
  let allOperations = currentDraftValue.operations;
  allOperations.forEach(result => {
    if (!result.affichage && (result.isMpr || result.isCommon)) {
      if (
        invisibleFieldArray.indexOf(
          result?.typeParameterCode?.trim().replaceAll(' ', '') ||
            (result?.balise != null && result?.balise.trim().replaceAll(' ', ''))
        ) > -1
      ) {
        fieldArrayMPR.push({
          baliseId: result.id,
          valeur:
            result.typeParameterCode == ''
              ? result.balise.trim()
              : result.typeParameterCode + ';' + result.typeParametreId,
        });
      } else {
        fieldArrayMPR.push({ baliseId: result.id, valeur: '' });
      }
    }
    if (!result.affichage && !result.isMpr) {
      if (
        invisibleFieldArray.indexOf(
          result?.typeParameterCode?.trim().replaceAll(' ', '') ||
            (result?.balise != null && result?.balise.trim().replaceAll(' ', ''))
        ) > -1
      ) {
        fieldArrayCEE.push({
          baliseId: result.id,
          valeur:
            result.typeParameterCode == ''
              ? result.balise.trim()
              : result.typeParameterCode + ';' + result.typeParametreId,
        });
      } else {
        fieldArrayCEE.push({ baliseId: result.id, valeur: '' });
      }
    }
    if (result.isQuantity) {
      fieldArrayCEE = fieldArrayCEE.filter(x => x.baliseId != result.id);
      fieldArrayCEE.push({
        baliseId: result.id,
        valeur: sectionBenefits
          ?.map(o => o.quantity)
          .reduce((a, c) => {
            return a + c;
          }),
      });
    }
  });

  if (mpr) {
    return fieldArrayMPR;
  } else {
    return fieldArrayCEE;
  }
}

export function prepareBenefitProductInformation(
  benefitProductInformation: BenefitProductInformation
): BenefitProductInformation {
  let benefitProductInformationToBeSaved: BenefitProductInformation = {
    marqueId: benefitProductInformation.marqueId,
    marque: benefitProductInformation.marque,
    referenceId: benefitProductInformation.referenceId,
    reference: benefitProductInformation.reference,
    genericProductId: benefitProductInformation.genericProductId,
    productType: benefitProductInformation.productType,
    benefitId: '00000000-0000-0000-0000-000000000000',
    unitHTPrice: benefitProductInformation.unitHTPrice,
    tva: benefitProductInformation.tva,
    unitTTCPrice: calculateUnitTTC(
      benefitProductInformation.unitHTPrice,
      benefitProductInformation.tva,
      benefitProductInformation.unitTTCPrice
    ),
    description: benefitProductInformation.description,
    displayedLabel: benefitProductInformation.displayedLabel,
    benefitProductArguments: benefitProductInformation.benefitProductArguments
      ? prepareBenefitProductArguments(benefitProductInformation.benefitProductArguments)
      : [],
    benefitProductCategories: benefitProductInformation.benefitProductCategories
      ? prepareBenefitProductCategories(benefitProductInformation.benefitProductCategories)
      : [],
    benefitProductArgCtCtfs: benefitProductInformation.benefitProductArgCtCtfs
      ? prepareBenefitProductArgCtCtfs(benefitProductInformation.benefitProductArgCtCtfs)
      : [],
  };
  return benefitProductInformationToBeSaved;
}

export function prepareBenefitProductArguments(
  benefitProductArguments: any[]
): BenefitProductArguments[] {
  return benefitProductArguments.map(argument => ({
    productArgumentId: argument.productArgumentId,
    rangedValue: argument.rangedValue,
    benefitProductInformationId: argument.benefitProductInformationId,
  }));
}

export function prepareBenefitProductCategories(
  benefitProductCategories: BenefitProductCategories[]
): BenefitProductCategories[] {
  return benefitProductCategories.map(category => ({
    productCategoryId: category.productCategoryId,
    benefitProductInformationId: category.benefitProductInformationId,
  }));
}

export function prepareBenefitProductArgCtCtfs(benefitProductArgCtCtfs: any[]): any[] {
  return benefitProductArgCtCtfs.map(argument => ({
    fieldId: argument.fieldId,
    label: argument.label,
    value: argument.value,
    dataType: argument.dataType,
    fieldType: argument.fieldType,
    benefitProductInformationId: argument.benefitProductInformationId,
  }));
}

export function transformBuildingAgeToBoolean(buildingAge: string): boolean {
  return buildingAge === '15' || buildingAge === '2' ? true : false;
}

export function SearchPacOperation(sections: Section[]) {
  let section = sections?.find(x => x?.operation?.label == 'Pompe à chaleur de type air/eau');
  if (section) {
    return section;
  }
  return undefined;
}

export function GetValueBasedOnKey(inputKey: string, list: any[]): any {
  for (const obj of list) {
    if (inputKey.toLowerCase() == obj.fieldName.toLowerCase()) {
      return obj.fieldValue;
    }
  }
  return undefined; // Key not found
}

export function nullOrNineDigitsValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    if (value === null || value === '') {
      return null; // No error if value is null or empty
    }

    const isValid = /^\d{9}$/.test(value);
    return isValid ? null : { nineDigits: { value: control.value } };
  };
}

export function generateConcatenatedHtml(
  designOffice: DesignOffice,
  fieldValues: FieldValue[]
): string {
  var designOfficeHtml = '';
  var listHTML = '';

  if (designOffice) {
    designOfficeHtml = `
  <div class='text-[#8e8f90] font-normal'>Bureau d'étude</div>
  <div class='text-[#8e8f90] flex flex-col'>
      <div>${designOffice?.raisonSociale}</div>
      <div><span class='font-normal'>Siren:</span> ${designOffice?.siren}</div>
      <div>${designOffice?.address + ', '}${designOffice?.zipCode + ', '}${designOffice?.city}</div>
  </div>
  <br>`;
  }

  if (fieldValues && fieldValues.length > 0) {
    listHTML = `<div class='text-[#8e8f90] font-normal flex flex-col'>`;
    fieldValues.forEach(fv => {
      listHTML += `<div>${fv.baliseInLabel}: <span class='font-semibold'>${fv.currentValue}</span></div>`;
    });
    listHTML += '</div>';
  }

  return designOfficeHtml + listHTML;
}

export function isValidGUID(guid: string): boolean {
  const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return guidPattern.test(guid);
}

export function convertToUTC(dateStr): Date {
  const date = new Date(dateStr);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
}

export function convertStringUtcToLocal(utcDateString: string): Date {
  const date = new Date(utcDateString);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
}

export function convertDateUtcToLocal(utcDateString: Date): Date {
  const date = new Date(utcDateString);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
}

export function convertUTCToLocal(utcDate: Date, timeZone: string): string {
  // Convert the date to the specified time zone
  const zonedDate = formatInTimeZone(utcDate, timeZone, 'yyyy-MM-dd HH:mm:ssXXX'); // 2014-10-25 06:46:20-04:00

  // Format the date to include the time zone offset
  return tzFormat(zonedDate, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone });
}

export function extractOperatorAndHours(timeZone: string): { operator: '+' | '-'; hours: number } {
  // Ensure the string starts with 'UTC'
  if (!timeZone.startsWith('UTC')) {
    throw new Error('Invalid time zone format. It should start with "UTC".');
  }

  // Extract operator ('+' or '-')
  const operator = timeZone.charAt(3) as '+' | '-';

  // Extract number of hours
  const hoursString = timeZone.substr(4);
  const hours = parseInt(hoursString, 10);

  // Return operator and hours as an object
  return { operator, hours };
}

export function addOrSubtractHours(date: Date, operator: '+' | '-', hours: number): Date {
  // Create a new Date object to avoid mutating the original date
  const newDate = new Date(date);

  if (operator === '+') {
    newDate.setHours(newDate.getHours() + hours);
  } else if (operator === '-') {
    newDate.setHours(newDate.getHours() - hours);
  } else {
    throw new Error('Invalid operator. Use "+" or "-"');
  }

  return newDate;
}

// Define the interface for the object structure
export interface DossierSettings {
  redirect: boolean;
  status: string[];
  actionDossierType;
  index: number;
  actionFilterType;
  title;
  isCee;
}

// Function to save the object to sessionStorage
export function saveToSessionStorage(settings: DossierSettings): void {
  // Convert the object to a JSON string
  const jsonData = JSON.stringify(settings);
  // Save to sessionStorage
  sessionStorage.setItem('dossierSettings', jsonData);
}

// Function to retrieve the object from sessionStorage
export function getFromSessionStorage(): DossierSettings | null {
  // Retrieve the JSON string from sessionStorage
  const jsonData = sessionStorage.getItem('dossierSettings');
  if (jsonData) {
    // Convert the JSON string back to an object
    return JSON.parse(jsonData);
  }
  // Return null if nothing is found in sessionStorage
  return null;
}

export function getSymbolForUnit(unit) {
  const unitSymbols: { [key: string]: string } = {
    'unité(s)': ' u',
    'heure(s)': ' h',
    m: ' m',
    'm²': ' m²',
    m3: ' m³',
    ml: ' ml',
    'Litre(s)': ' L',
    'gramme(s)': ' g',
    kg: ' kg',
    mm: ' mm',
    cm: ' cm',
    'cm²': ' cm²',
    cm3: ' cm³',
    km: ' km',
    'km²': ' km²',
    mg: ' mg',
    lb: ' lb',
    fft: ' fft',
  };
  
  return unitSymbols[unit];
}

// Number((unitHTPrice * (1 + tva / 100))?.toFixed(2));
export function calculateUnitTTC(
  htPrice: number | null,
  tvaRate: number | null,
  unitttc: number
): number {
  if (!+htPrice || !+tvaRate) {
    return unitttc;
  }

  const calculatedUnitTTC = +htPrice + (+htPrice * +tvaRate) / 100;
  return parseFloat(calculatedUnitTTC.toFixed(2));
}

export function calculateTotalTTC(
  htPrice: number | null,
  tvaRate: number | null,
  quantity: number | null,
  ttc: number
): number {
  if (!+htPrice || !+tvaRate || !+quantity) {
    return ttc;
  }

  const calculatedUnitTTC = +htPrice + (+htPrice * +tvaRate) / 100;
  const calculatedTotalTTC = calculatedUnitTTC * +quantity;
  return parseFloat(calculatedTotalTTC.toFixed(2));
}


export function filterDuplicates(arr) {
  if (!arr || arr.length === 0) {
    return []; // Return an empty array if input is null or empty
  }

  const result = {};
  arr.forEach(item => {
    result[item.balise] = item.valeur; // Overwrite if balise already exists
  });

  return Object.keys(result).map(key => ({ balise: key, valeur: result[key] }));
}
