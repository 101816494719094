export const environment = {
  production: true,
  encryptionKey: 'SICA-APP-KEY',
  appVersion: require('../../package.json').version + '-dev',
  DOMAIN: 'https://dev.sica-app.com',
  showErrors: true,
  environmentName: 'dev',

  //IDENTITY_URL: 'https://localhost:5001/',
  //RESOURCE_API: 'https://localhost:5002/',
  IDENTITY_URL: 'https://dev.identity.sica-app.com/',
  RESOURCE_API: 'https://dev.ressources.sica-app.com/',
  CLONE_APP: 'https://clone.sica-app.com',

  GEO_PORTAL: 'https://www.geoportail.gouv.fr/carte?c=${longitude},${latitude}&z=20&l0=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2::GEOPORTAIL:OGC:WMTS(1)&l1=CADASTRALPARCELS.PARCELLAIRE_EXPRESS::GEOPORTAIL:OGC:WMTS(1)&permalink=yes',


  // Tracking 
  MATOMO_APP_ID: 3,
  // API List
  apiCatalog: {
      health: 'health',
      taxInformation: 'taxIdentity/',
      userStoredParams: {
          controllerRoute: 'api/UserStoredParameters/',
          subRoutes: {
              getByEmail: 'api/UserStoredParameters/GetByEmail/',
              set: 'api/UserStoredParameters/Add',
              remove: 'api/UserStoredParameters/Remove/',
              setActive: 'api/UserStoredParameters/SetActive/',
          }
      },
      pdf: {
          exportDevisPdf: 'api/exportDevisPdf',
          exportInvoicePdf: 'api/exportInvoicePdf'
      },
      acteur: {
          controllerRoute: 'api/Acteur',
          subRoutes: {
              getAll: '/GetAllActive',
              getAllActivePartners: '/GetAllActivePartners',
              getBySiret: '/GetBySiret/'
          }
      },
      product: {
          controllerRoute: 'api/Product',
          subRoutes: {
              getAllActive: 'api/Benefit/GetAllActive',
              getAll: 'api/Product/GetAll',
              update: 'api/Product/update',
              add: 'api/Product/add',
              delete: 'api/Product/delete',
              remove: 'api/Product/remove',
              addMultiple: 'api/Product/addMultiple',
              updateMultiple: 'api/Product/updateMultiple',
              deactivateProduct: 'api/Product/deactivateProduct',
              activateProduct: 'api/Product/activateProduct',
              deactivateProducts: 'api/Product/deactivateProducts',
              removeMultiple: 'api/Product/removeMultiple',

          }
      },
      genericProduct: {
          controllerRoute: 'api/GenericProduct',
          subRoutes: {
              getAllActive: 'api/GenericProduct/GetAllActive',
              getCategories: 'api/GenericProduct/GetCategories',
              getBrands: 'api/GenericProduct/GetBrands',
              getReferences: 'api/GenericProduct/GetReferences',
              getProduct: 'api/GenericProduct/GetProduct',
              getCTs: 'api/GenericProduct/GetCTs',
              getCTFs: 'api/GenericProduct/GetCTFs',
              getArguments: 'api/GenericProduct/GetArguments',
              getAll: 'api/GenericProduct/GetAll',
              update: 'api/GenericProduct/update',
              add: 'api/GenericProduct/add',
              delete: 'api/GenericProduct/delete',
              remove: 'api/GenericProduct/remove',
              getGenericProductsByGenericOperationId: 'api/GenericProduct/GetGenericProductsByGenericOperationId',
              produitAdditionalCtByProduct: 'api/GenericProduct/ProduitAdditionalCtByProduct',
          }
      },
      benefit: {
          controllerRoute: 'api/Benefit',
          subRoutes: {
              getById: 'api/Benefit/GetById/',
              getAllActive: 'api/Benefit/GetAllActive',
              getBenefitPresetsByCompany: 'api/Benefit/GetBenefitPresetsByCompany/',
              getBenefitModelPresetsByCompany: 'api/Benefit/GetBenefitModelPresetsByCompany/',
              update: 'api/benefit/update',
              addMultiple: 'api/Benefit/addMultiple',
              updateMutiple: 'api/Benefit/updateMultiple',
              deactivateBenefit: 'api/Benefit/deactivateBenefit',
              activateBenefit: 'api/Benefit/activateBenefit',
              deactivateBenefits: 'api/Benefit/deactivateBenefits',
              remove: 'api/Benefit/remove',
              delete: 'api/Benefit/delete',
              deleteSection: 'api/Benefit/clear-sections',
              cloneDevis: 'api/Benefit/clone-devis',
              GetBenefitPresetsSuperAdmin: 'api/Benefit/GetBenefitPresets',
              getBenefitsGroupedByDevisModel: 'api/Benefit/GetBenefitsGroupedByDevisModel/',
              getBenefitsBydevisId: 'api/Benefit/GetBenefitsByDevisId/',
              saveBenefitBySection: 'api/Benefit/saveBenefitBySection/',
              updateBenefitBySection: 'api/Benefit/updateBenefitBySection/',
              getBenefitsBySectionId: 'api/Benefit/GetBenefitsBySectionId/',
              addOrUpdateBenefitByAdmin: 'api/Benefit/AddOrUpdateBenefitByAdmin',
              addBenefitProduct: 'api/Benefit/AddBenefitProduct',
              getBenefitProduct: 'api/Benefit/GetBenefitProduct',
              updateBenefitProduct: 'api/Benefit/UpdateBenefitProduct',
              deactivateBenefitProduct: 'api/Benefit/DeactivateBenefitProduct',
              deleteBenefitFromSectionByBenefitId: 'api/Benefit/DeleteBenefitFromSectionByBenefitId',
              addOrUpdateBenefitModel: 'api/Benefit/AddOrUpdateBenefitModel',
              reorderBenefit: 'api/Benefit/ReorderBenefit/',
          }
      },
      section: {
          controllerRoute: 'api/Section',
          subRoutes: {
              getById: 'api/Section/GetById/',
              update: 'api/Section/update',
              addMultiple: 'api/Section/addMultiple',
              removeMultiple: 'api/Section/removeMultiple',
              remove: 'api/Section/remove',
              delete: 'api/Section/delete',
              getByDevisId: 'api/Section/GetByDevisId/',
              updateWithNewOperation: 'api/Section/updateWithNewOperation',
              saveBonusBySection: 'api/Section/saveBonusBySection',
              updateBonusBySection: 'api/Section/updateBonusBySection',
              updateSection: 'api/Section/updateSection',
              duplicateSection: 'api/Section/duplicateSection',
              duplicateMultipleSection: 'api/Section/duplicateMultipleSection',
              updateBonusMultiple: 'api/Section/updateBonusMultiple',
              getSectionProductBenefitPrimeById: 'api/Section/GetSectionProductBenefitPrimeById',
              updateSectionModel: 'api/Section/UpdateSectionModel',
              getEligiblityConditionsAlertsByDevis: 'api/Section/GetEligiblityConditionsAlertsByDevis',
              reorderSection: 'api/Section/ReorderSection/',
          }
      },
      subcontractor: {
          controllerRoute: 'api/Subcontractor',
          subRoutes: {
              getById: 'api/Subcontractor/GetById/',
              getAllActive: 'api/Subcontractor/GetAllActive',
              getAll: 'api/Subcontractor/GetAll',
              delete: 'api/Subcontractor/delete',
              update: 'api/Subcontractor/update'
          }
      },
      devis: {
          controllerRoute: 'api/Devis',
          subRoutes: {
              getById: 'api/Devis/GetById/',
              getAllActive: 'api/Devis/GetAllActive',
              getAll: 'api/Devis/GetAll',
              getDevisModelByCompanyId: 'api/Devis/GetDevisModelByCompanyId/',
              getDevisByCustomer: 'api/Devis/GetDevisByCustomer/',
              addReductionAmount: 'api/Devis/AddReductionAmount',
              updateLastVersionStatus: 'api/Devis/UpdateLastVersionStatus',
              update: 'api/Devis/update',
              delete: 'api/Devis/delete',
              remove: 'api/Devis/remove',
              updateDevisNumber: 'api/Devis/UpdateDevisNumber',
              updateCreationDate: 'api/Devis/UpdateCreationDate',
              exportDevisPdf: 'api/Devis/exportPDF',
              updateDevisModel: 'api/Devis/UpdateDevisModel',
              updateDevisSuperVisor: 'api/Devis/UpdateDevisSuperVisor',
              checkDevisSigned: 'api/Devis/CheckDevisSigned/',
          }
      },
      devisVersion: {
          controllerRoute: 'api/DevisVersion',
          subRoutes: {
              getByDevisId: 'api/DevisVersion/GetByDevisId/',
              getById: 'api/DevisVersion/GetById/',
              getAllActive: 'api/DevisVersion/GetAllActive',
              getAll: 'api/DevisVersion/GetAll',
              add: 'api/DevisVersion/add',
              getDevisVersionCurrentStatus: 'api/DevisVersion/GetDevisVersionCurrentStatus/',
              UploadSignedDevis: 'api/DevisVersion/UploadSignedDevis/',
          },
      },
      invoice: {
          controllerRoute: 'api/Invoice',
          subRoutes: {
              getById: 'api/Invoice/GetById/',
              getAllActive: 'api/Invoice/GetAllActive',
              getInvoiceByDevis: 'api/Invoice/GetInvoiceByDevis/',
              updateLastVersionStatus: 'api/Invoice/UpdateLastVersionStatus',
              updateInvoiceNumber: 'api/Invoice/UpdateInvoiceNumber',
              updateCreationDate: 'api/Invoice/UpdateCreationDate',
              exportInvoicePdf: 'api/Invoice/exportPDF',
          }
      },
      numberingSettings: {
          controllerRoute: 'api/NumberingSettings',
          subRoutes: {
              getById: 'api/NumberingSettings/GetById/',
              getAllActive: 'api/NumberingSettings/GetAllActive',
              update: 'api/NumberingSettings/update',
              delete: 'api/NumberingSettings/delete'
          }
      },
      customer: {
          controllerRoute: 'api/Customer',
          subRoutes: {
              getById: 'api/Customer/GetById/',
              getAllActive: 'api/Customer/companyId/GetAllActive',
              update: 'api/Customer/update',
              add: 'api/Customer/add',
              addRange: 'api/Customer/addRange',
              getAllCustomers: 'api/Customer/GetAllCustomers/',
              canAccessCustomer: 'api/Customer/CanAccessCustomer/',
              dossierSubmittedInformations: 'api/Customer/DossierSubmittedInformations/',
              customerByDossierId: 'api/Customer/CustomerByDossierId/',
          }
      },
      company: {
          controllerRoute: 'api/Company',
          subRoutes: {
              getAll: 'api/Company/GetAll',
              getAllActive: 'api/Company/GetAllActive',
              update: 'api/Company/update',
              insee_siren: 'api/Company/GetInformation/',
              getById: 'api/Company/GetById/',
              companySettings: 'api/Company/companySettings/',
              delete: 'api/Company/delete',
              remove: 'api/Company/remove',
              getQualifications: 'api/Company/GetQualifications/',
              getQualificationBySiret: 'api/Company/GetQualificationBySiret/',
              getCompanyBySiret: 'api/Company/GetCompanyBySiret/',
          }
      },
      legalNotice: {
          controllerRoute: 'api/LegalNotice',
          subRoutes: {
              addMultiple: 'api/LegalNotice/addMultiple',
              getLegalNoticesByCompany: 'api/LegalNotice/',
              getLegalNoticesByDevis: 'api/LegalNoticeByDevis/',
              getLegalNoticesBySuperAdmin: 'api/CommonLegalNotices',
              addMultipleToDevis: 'api/LegalNotice/addMultiple/',
              restorDefaultLegalNotice: 'api/LegalNotice/RestoreDefaultLegalNotice/',
          }
      },
      user: {
          controllerRoute: 'api/User/',
          subRoutes: {
              update: 'api/User/update',
              getAllActive: 'api/User/GetAllActive',
              getAll: 'api/User/GetAll',
              getCount: 'api/User/GetCount',
              delete: 'api/User/Delete',
              deactivate: 'api/User/Deactivate',
              getCountAllUsers: 'api/User/GetCountAllUsers',
              cloneDatabase: 'api/User/CloneDatabase',
              activate: 'api/User/Activate',
              signIn: 'api/User/SignIn/'
          }
      },
      role: {
          controllerRoute: 'api/Role/',
          subRoutes: {
              update: 'api/Role/update',
              getAllActive: 'api/Role/GetAllActive',
              getAllCommon: 'api/Role/GetAllCommon',
              getAll: 'api/Role/GetAll',
              delete: 'api/Role/Delete',
              remove: 'api/Role/Remove',
              addMultiple: 'api/Role/addMultiple',
          }
      },
      permission: {
          controllerRoute: 'api/Permission',
          subRoutes: {
              update: 'api/Permission/Update',
              getAllActive: 'api/Permission/GetAllActive',
              getAllCommon: 'api/Permission/GetAllCommon',
              getAll: 'api/Permission/GetAll',
              delete: 'api/Permission/Delete',
              remove: 'api/Permission/Remove'
          }
      },
      building: {
          controllerRoute: 'api/Building/',
          subRoutes: {
              getCadastral: 'api/Building/GetCadastral/',
              getAddress: 'api/Building/GetAddress/'
          }
      },
      ged: {
          controllerRoute: 'api/Ged/',
          subRoutes: {
              getFolderContent: 'get-folder-content/{node}',
              getFileContent: 'get-file-content/{node}/{filename}',
              createFolder: 'create-folder/{name}/{title}/{description}/{node}',
              uploadFile: 'upload-file',
              deleteNode: 'delete',
              downloadAndCreateZip: 'download-fileAsZip',
              renameNode: 'rename-Node'
          }
      },
      DevisDocumentsHistory: {
          controllerRoute: 'api/DevisDocumentsHistory/',
          subRoutes: {
              addOperation: 'Add',
              getByDevis: 'api/DevisDocumentsHistory/{type}/{devisId}'
          }
      },
      mandate: {
          controllerRoute: 'api/MPRMandate/',
          subRoutes: {
              getAll: 'api/MPRMandate/GetAll',
              getActive: 'api/MPRMandate/GetAllActive',
              getById: 'api/MPRMandate/{Id}',
              delete: 'api/MPRMandate/delete',
              update: 'api/MPRMandate/update',
              deactivate: 'api/MPRMandate/deactivate',
              getFavorite: 'api/MPRMandate/GetFavorite',
              getAllMandates: 'api/MPRMandate/GetAllMandates',
              favoriteMandate: 'api/MPRMandate/FavoriteMandate/'
          }
      },
      gedReferences: {
          controllerRoute: 'api/GEDCustomerReferences/',
          subRoutes: {
              getCustomerGEDReferences: 'getByCutomerId/',
              update: 'update'
          }
      },
      operation: {
          controllerRoute: 'api/OperationGenerique/',
          subRoutes: {
              getGenericOperations: 'api/OperationGenerique/get-all',
              getGenericOperationsBalise: 'api/OperationGenerique/get-all-balise',
              getGenericOperationCase: 'api/OperationGenerique/get-cases/',
              checkAvailableServices: 'api/OperationGenerique/checkAvailableServices/',
          }
      },
      delegate: {
          controllerRoute: 'api/Delegate/',
          subRoutes: {
              getAll: 'api/Delegate/GetAll',
              getAllActive: 'api/Delegate/GetAllActive',
              getById: 'api/Delegate/GetById/',
              add: 'api/Delegate',
              update: 'api/Delegate/update',
              delete: 'api/Delegate/delete',
              remove: 'api/Delegate/remove',
              getFavorite: 'api/Delegate/GetFavorite',
              defaultMandateDelegate: 'api/Delegate/GetDefaultMandateDelegate',
              getMandateDelegate: 'api/Delegate/GetMandateDelegate',
              getAllDelegates: 'api/Delegate/GetAllDelegates',
              favoriteDelegate: 'api/Delegate/FavoriteDelegate/',
              getMandateDelegateByDevis: 'api/Delegate/GetMandateDelegateByDevis/',
          }
      },
      ficheOperation: {
          controllerRoute: 'api/fo/',
          subRoutes: {
              checkFicheHasCdp: 'api/fo/check-fiche-operation-has-cdp/',
              getFicheOperation: 'api/fo/get-fiche-operation/',
              getCas: 'api/fo/get-cas/',
              getChamps: 'api/fo/get-champ/',
              getVolume: 'api/fo/calcul-volume/',
              getCeeMprFields: 'api/fo/getMprCeeFields/',
              getCeeMprCases: 'api/fo/getMprCeeCases/',
              getBrands: 'api/fo/get-marques/',
              getReferences: 'api/fo/get-references/',
              recomputeBonus: 'api/fo/recomputeBonus/',
              recomputeRenoGlobalBonus: 'api/fo/recomputeRenoGlobalBonus/',
              getChampsForRenoGlobal: 'api/fo/renoGlobaleFields/'
          }
      },
      equipementMPR: {
          controllerRoute: 'api/equipementMpr/',
          subRoutes: {
              getEquipement: 'api/equipementMpr/get-equipement/',
              getCas: 'api/equipementMpr/get-cas/',
              getChamps: 'api/equipementMpr/get-champ/',
              getVolume: 'api/equipementMpr/calcul-montant/'
          }
      },
      parameter: {
          controllerRoute: 'api/Parametre/',
          subRoutes: {
              getAllByType: 'api/Parametre/get-all-by-type/',
              zoneClimatiique: 'api/Parametre/zone-climatique/'
          }
      },
      typePrecarity: {
          controllerRoute: 'api/TypePrecarite/',
          subRoutes: {
              precarityCee: 'api/TypePrecarite/get-type-preca-cee/',
              colorMpr: 'api/TypePrecarite/get-couleur-mpr/'
          }
      },
      documentGenerator: {
          controllerRoute: 'api/DocumentGenerator/',
          subRoutes: {
              printCDC: 'doc-generator/print-cdc?date=',
              printAH: 'doc-generator/print-ah?date=',
              printMandat: 'doc-generator/print-mandat?date=',
              generateTextDevisFacture: 'doc-generator/text-devis-facture/',
              printEtudeDimensionnement: 'doc-generator/print-etude-dimensionnement?date=',
              multiplePrintEtudeDimensionnement: 'doc-generator/multiple-print-etude-dimensionnement?date=',
              dossierFromCustomer  : 'doc-generator/dossier-from-customer/'
          }
      },
      tag: {
          controllerRoute: 'api/tag',
          subRoutes: {
              getById: '/getById/',
              getAllActive: '/getAllActive',
              delete: '/delete',
              update: '/update'
          }
      },
      documentNature: {
          controllerRoute: 'api/DocumentNature',
          subRoutes: {
              getById: '/getById',
              getAllActive: '/getAllActive',
              delete: '/delete',
              update: '/update'
          }
      },
      gedMarker: {
          controllerRoute: 'api/GEDMarker',
          subRoutes: {
              getById: '/getById',
              getAllActive: '/getAllActive',
              delete: '/delete',
              update: '/update'
          }
      },
      garbageCollector: {
          controllerRoute: 'api/GarbageCollector',
          subRoutes: {
              getById: '/getById',
              getAllActive: '/getAllActive',
              disable: '/disable',
              update: '/update',
          }
      },
      garbageDevisInformation: {
          controllerRoute: 'api/garbageDevisInformation',
          subRoutes: {
              getById: '/getById',
              getAllActive: '/getAllActive',
              disable: '/disable',
              update: '/update',
          }
      },
      validationTriggers: {
          controllerRoute: 'api/ValidationTriggers',
          subRoutes: {
              getById: '/getById',
              getAllActive: '/getAllActive',
              getAll: '/getAll',
              disable: '/disable',
              update: '/update',
              remove: '/remove',
              delete: '/delete'
          }
      },
      validationRules: {
          controllerRoute: 'api/ValidationRules',
          subRoutes: {
              getById: '/getById',
              getAllActive: '/getAllActive',
              getAll: '/getAll',
              disable: '/disable',
              update: '/update',
              remove: '/remove',
              delete: '/delete',
              rename: '/rename'
          }
      },
      beneficiarystatus: {
          controllerRoute: 'api/BeneficiaryStatus',
          subRoutes: {
              getById: '/getById',
              getByDevisId: '/GetBeneficiaryStatusByDevis',
              getAllActive: '/getAllActive',
              getByCompanyId: '/GetByCompanyId',
              add: '/add',
              update: '/update',
              remove: '/remove',
              updateRange: '/updateRange'
          },
      },
      validationRulesHandler: {
          controllerRoute: 'api/ValidationRulesHandlers',
          subRoutes: {
              handle: '/handle',
              reEvaluate: '/reEvaluate'
          }
      },
      beneficiarystatusType: {
          controllerRoute: 'api/BeneficiaryStatusType',
          subRoutes: {
              getById: '/getById',
              getAllActive: '/getAllActive',
              getByCompanyId: '/GetByCompanyId',
              getCommon: '/GetCommon',
              add: '/add',
              update: '/update',
              remove: '/remove',
              updateRange: '/updateRange'
          }
      },
      ruleAlert: {
          controllerRoute: 'api/RuleAlert',
          subRoutes: {
              getAll: '/getAll',
              getAllByCustomerId: '/getAllByCustomerId',
          }
      },
      supportRequest: {
          controllerRoute: 'api/SupportRequest',
          subRoutes: {
              getAll: '/GetAll',
              update: '/update',
          }
      },
      deal: {
          controllerRoute: 'api/Deal',
          subRoutes: {
              getById: '/getById',
              getByCompany: '/GetByCompany',
          }
      },
      dealOperation: {
          controllerRoute: 'api/DealOperation',
          subRoutes: {
              getByDeal: '/GetByDeal',
          }
      },
      team: {
          controllerRoute: 'api/teamsUsers/',
          subRoutes: {
              update: 'api/team/Update',
              getAllActive: 'api/team/GetAllActive',
              getAll: 'api/team/GetAll',
              getCount: 'api/team/GetUsersCount',
              delete: 'api/team/Delete',
              removeFromTeam: 'api/team/RemoveFromTeam',
              addUsersToTeam: 'api/team/AddUsersToTeam/',
              addTeam: 'api/team/Add/',
              addUpdateTeam: 'api/team/AddUpdateTeam/',
          }
      },
      infoTooltip: {
          controllerRoute: 'api/infoTooltipMessage/',
          subRoutes: {
              update: 'api/infoTooltipMessage/Update',
              delete: 'api/infoTooltipMessage/Delete',
              add: 'api/infoTooltipMessage/Add',
              getByPage: 'api/infoTooltipMessage/GetAllActive/',
              getByName: 'api/infoTooltipMessage/GetActiveByName/',
              getByButtonId: 'api/infoTooltipMessage/GetActiveByButtonId/',
              getAllActive: 'api/infoTooltipMessage/GetAllActive',
          }
      },
      sectionMessage: {
          controllerRoute: 'api/sectionMessage/',
          subRoutes: {
              update: 'api/sectionMessage/Update',
              delete: 'api/sectionMessage/Delete',
              add: 'api/sectionMessage/Add',
              getByPage: 'api/sectionMessage/GetAllActive/',
              getByName: 'api/sectionMessage/GetActiveByName/',
              getBySectionId: 'api/sectionMessage/GetActiveBySectionId/',
              getAllActive: 'api/sectionMessage/GetAllActive',
          }
      },
      resource: {
          controllerRoute: 'api/resource/',
          subRoutes: {
              update: 'api/Resource/Update',
              getAllActive: 'api/Resource/GetAllActive',
              getAll: 'api/Resource/GetAll/',
              delete: 'api/Resource/Delete',
              getByCompany: 'api/Resource/GetAllActive/',
              add: 'api/Resource/Add',
              getAllActiveGroupedByCategoryByCompanyId: 'api/Resource/GetAllActiveGroupedByCategoryByCompanyId/'
          }
      },
      event: {
          controllerRoute: 'api/SicaEvent/',
          subRoutes: {
              update: 'api/SicaEvent/Update',
              getAllActive: 'api/SicaEvent/GetAllActive',
              getByCompany: 'api/SicaEvent/GetAllActive/',
              getAll: 'api/SicaEvent/GetAll',
              delete: 'api/SicaEvent/Delete',
              addEvent: 'api/SicaEvent/Add/',
              getByCustomer: 'api/SicaEvent/GetAllActiveByCustomer/',
          }
      },
      unavailability: {
          controllerRoute: 'api/Unavailability/',
          subRoutes: {
              update: 'api/Unavailability/Update',
              getAllActive: 'api/Unavailability/GetAllActive',
              delete: 'api/Unavailability/Delete',
              getAllActiveByResourceId: 'api/Unavailability/GetAllActive/',
              add: 'api/Unavailability/Add'
          }
      },
      eventType: {
          controllerRoute: 'api/SicaEventType/',
          subRoutes: {
              update: 'api/SicaEventType/Update',
              getAllActive: 'api/SicaEventType/GetAllActive',
              getCommon: 'api/SicaEventType/GetCommon',
              getAllActiveByCompany: 'api/SicaEventType/GetAllActiveByCompany/',
              delete: 'api/SicaEventType/Delete',
              addEvent: 'api/SicaEventType/Add/'
          }
      },
      faq: {
          controllerRoute: 'api/Faq/',
          subRoutes: {
              update: 'api/Faq/Update',
              getAllActive: 'api/Faq/GetAllActive',
              getAllActiveEnabled: 'api/Faq/GetAllActiveEnabled',
              delete: 'api/Faq/Delete',
              add: 'api/Faq/Add/'
          }
      },
      faqSection: {
          controllerRoute: 'api/FaqSection/',
          subRoutes: {
              update: 'api/FaqSection/Update',
              getAllActive: 'api/FaqSection/GetAllActive',
              getAll: 'api/FaqSection/GetAll',
              delete: 'api/FaqSection/Delete',
              add: 'api/FaqSection/Add/',
          }
      },
      eligibilityCondition: {
          subRoutes: {
              isProduitEligibleForFO: 'api/fo/is-produit-eligible-for-fo/',
              isProduitEligibleForEquipementMPR: 'api/fo/is-produit-eligible-for-equipementMPR/',
          }
      },
      storageItem: {
          controllerRoute: 'api/StorageItem/',
          subRoutes: {
              getFolderContent: 'GetFolderContent',
              getCustomerFolders: 'GetCustomerFolders/',
              createFreeFolder: 'CreateFreeFolder/',
              renameFreeFolder: 'RenameFreeFolder/',
              deleteFreeFolder: 'DeleteFreeFolder/',
              addTagToFile: 'AddTagToFile/',
              addTagToFolder: 'AddTagToFolder/',
              updateFileWithDocNature: 'UpdateFileWithDocNature/',
              renameBlobAndUpdateDatabase: 'RenameBlobAndUpdateDatabase/',
              deleteFileFromDatabase: 'DeleteFileFromDatabase/',
              deleteTagFromFolder: 'DeleteTagFromFolder/',
              getTagsByFolder: 'GetTagsByFolder/',
              deleteTagFromFile: 'DeleteTagFromFile/',
              getTagsByFile: 'GetTagsByFile/',
              deleteFolderFromDatabase: 'DeleteFolderFromDatabase/',
              getIsLastVersionDevisInvoice: 'GetIsLastVersionDevisInvoice/',
              getCompanyFolders: 'GetCompanyFolders/',
              getPartnerDropFoldersHistory: 'GetPartnerDropFoldersHistory/',
              getAllFolderContent: 'GetAllFolderContent/',
              checkIfDossierReady: 'CheckIfDossierReady'
          }
      },
      blob: {
          controllerRoute: 'api/blob/',
          subRoutes: {
              GetLastVersion: 'GetLastVersion/',
              getAll: 'GetAll/',
              uploadBlob: 'Upload',
              uploadSignedBlob: 'UploadSigned/',
              download: 'Download',
              downloadFolder: 'DownloadFolder',
              createZip: 'CreateZip/',
              downloadDossierContentZip: 'DownloadDossierContentZip/',
              loadFolderDocuments: 'LoadFolderDocuments/',
              downloadFolderFile: 'DownloadFolderFile',
              getCustomerDossier: 'GetCustomerDossier/'
          }
      },
      actor: {
          controllerRoute: 'api/actor/',
          subRoutes: {
              getActorsByCompanyIdEvenTypeId: 'GetActorsByCompanyIdEvenTypeId/',
              getActorsByCompanyId: 'GetActorsByCompanyId/',
              getActorsRelatedToCustomer: 'GetActorsRelatedToCustomer/',
              checkActorOverLap: 'CheckActorOverLap/',
          }
      },
      dossier: {
          controllerRoute: 'api/Dossier/',
          subRoutes: {
              exportPartnersFolders: 'ExportPartnersFolders/',
              getDossiers: 'GetDossiers/',
              getFolderStatuses: 'GetDossierStatuses/',
              folderComment: 'Comment/',
              getBillings: 'Billings/',   
              extractAll: 'ExtractDossier/'
          }
      },
      eventStatus: {
          controllerRoute: 'api/eventStatus/',
          subRoutes: {
              eventStatusAdd: 'api/eventStatus/add',
              eventStatusUpdate: 'api/eventStatus/update',
              updateMultiple: 'api/eventStatus/UpdateMultiple',
              deactivateEventStatus: 'api/eventStatus/DeactivateEventStatus/',
              getEventStatusByCompanyId: 'api/eventStatus/GetEventStatusByCompanyId/',
              getEventStatusBySuperAdmin: 'api/eventStatus/GetEventStatusBySuperAdmin/',
          }
      },
      mapUtility: {
          controllerRoute: 'api/MapUtility/',
          subRoutes: {
              getRouteData: 'api/MapUtility/GetRouteData/',
          }
      },
      eSignature: {
          controllerRoute: 'api/eSignature/',
          subRoutes: {
              createEnvelope: 'createEnvelope',
              getEnvelope: 'getEnvelope/',
              getEnvelopeDocuments: 'getEnvelopeDocuments/',
              sendReminder: 'sendReminder/',
              voidEnvelope: 'voidEnvelope/',
          }
      },
      mail: {
          controllerRoute: 'api/send-mail/',
          subRoutes: {
              sendContactMail: 'api/send-contact-mail/',
          }
      },
      etudeDimensionnement: {
          controllerRoute: 'api/EtudeDimensionnement/',
          subRoutes: {
              add: 'api/EtudeDimensionnement/add',
              update: 'api/EtudeDimensionnement/update',
          }
      },
      designOffice: {
          controllerRoute: 'api/DesignOffice',
          subRoutes: {
              getAll: '/GetAll',
              add: '/Add',
              update: '/Update',
              delete: '/Delete',
              getAllActive: '/GetAllActive',
          }
      },
      partners: {
          controllerRoute: 'api/Partners',
          subRoutes: {
              notifyDropPartnerFolder: '/NotifyDropPartnerFolder',
              getDropHistory: '/GetDropHistory/'
          }
      },
      partnerContracts: {
          controllerRoute: 'api/Contrat',
          subRoutes: {
              getAll: '/getAll/',
          }
      },
      chat: {
          controllerRoute: 'api/chat',
          subRoutes: {
              getChatByFolderId: '/get-by-folder/',
              sendMessage: '/send-message',
              getFolderHistory: '/GetFolderHistory/'
          }
      },
      pdfContract: {
          controllerRoute: 'api/PdfContract',
          subRoutes: {
              downloadContractCee: '/cee/',
              downloadContractMpr: '/mpr/'
          }
      },
      espacePartenaire: {
          controllerRoute: 'api/EspacePartenaire',
          subRoutes: {
              factures: '/get-factures',
              generalFactures: '/get-general-factures',
              facturesMpr: '/get-factureMPRs',
              generalKpi: '/get-general-kpi',
              kpiDashboard: '/get-kpi-dashboard',
              qualityMonitoring: '/get-quality-monitoring',
          }
      },
      suiviDossier: {
          controllerRoute: 'api/SuiviDossier',
          subRoutes: {
              dossierLayoutMetrics: 'api/SuiviDossier/dossierLayoutMetrics/',
              dossierByStatus: 'api/SuiviDossier/dossierByStatus/',
              dossierPartnerHasContratMpr: 'api/SuiviDossier/dossierPartnerHasContratMpr/',
              getPaginatedDossierByStatus: 'api/SuiviDossier/getAll/',
          }
      },
      pdfFacture: {
          controllerRoute: 'api/PdfFacture',
          subRoutes: {
              ceeAppelFacturation: '/ceeAppelFacturation/',
              ceeFacture: '/ceeFacture/',
              mprDocumentFacturationLot: '/mprDocumentFacturationLot/',
          }
      },
      partnerDossierExtraFiles: {
          controllerRoute: 'api/PartnerDossierExtraFiles',
          subRoutes: {
              rapportBc: '/rapportBc/',
              bordereau: '/bordereau/',
              downloadRapportBc: '/downloadRapportBc/',
              downloadBordereau: '/downloadBordereau/',
          }
      },
      partnerMessage: {
          controllerRoute: 'api/PartnerMessage',
          subRoutes: {
              getAllMessages: '/getAll',
              getAllActive: '/GetAllActive',
              add: '/Add',
              delete: '/Delete/',
              update: '/Update',
          }
      },
      utilisateur: {
          controllerRoute: 'api/Utilisateur',
          subRoutes: {
              getUserInfoById: '/GetUserInfoById/',
              findAcepUserByEmail: '/FindAcepUserByEmail/',
          }
      },
  },

  identityCatalog: {
      admin: {
          controllerRoute: 'api/admin/',
          subRoutes: {
              createUser: 'api/admin/create-user',
              deleteUser: 'api/admin/delete-user',
              createTenant: 'api/admin/create-tenant',
              updateTeant: 'api/admin/update-tenant',
              getRoles: 'api/admin/get-roles',
              getTenants: 'api/admin/get-tenants',
              enableDisableUser: 'api/admin/enable-disable-user',
              onboardUser: 'api/admin/onboard-user',
              resetPassword: 'api/admin/reset-password',
              enableDisableTenant: 'api/admin/enable-disable-tenant',
              resendToken: 'api/admin/re-send-onboarding-token',
              onboardAcepUser: 'api/admin/onboard-acep-user',
              onboardAcepPartner: 'api/admin/onboard-acep-partner',
          }
      }
  },
};

