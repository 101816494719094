import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap, take } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { UserPermission } from 'app/modules/models/profile-management/userPermission.type';
import { UserService } from 'app/core/user/user.service';
import { isSuperAdmin } from 'app/shared/utils';
import { User } from 'app/core/user/user.types';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanActivateChild, CanLoad {
    /**
     * Constructor
     */
    permissions: UserPermission[] = [];
    constructor(
        private _authService: AuthService,
        private _userService: UserService,
        private _router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
         const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
         return this._check(redirectUrl);
        return true;
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
         const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
         return this._check(redirectUrl);       
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        const redirectUrl = route.path === 'sign-out' ? '/' : route.path;
        return this._check(redirectUrl);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(redirectURL: string): Observable<boolean> {
        // Check the authentication status
        return this._authService.check()
            .pipe(
                switchMap((authenticated) => {

                    // If the user is not authenticated...
                    if (!authenticated) {
                        // Redirect to the sign-in page
                        this._router.navigate(['sign-in'], { queryParams: { redirectURL } });

                        // Prevent the access
                        return of(false);
                    }
                    if (isSuperAdmin()) {
                        return of(true);
                    }
                    if(redirectURL == '/settings/landing' || redirectURL == '/settings/profile')
                    {
                        return of(true);
                    }                   
                    // Allow the access



                    let user = null;
                    const connectedUserData = localStorage.getItem("connected_user");
                    if (connectedUserData) {
                      try {
                          // Parse the connected_user from localStorage
                          user = JSON.parse(connectedUserData);
                      } catch (error) {
                        return of(true);
                      }
                      } else {
                        return of(true);
                    }


                    return new Promise<boolean>((resolve, reject) => {
                        this._userService.get(JSON.parse(localStorage.getItem('connected_user'))?.email).subscribe((user: User) => {
                          this.permissions = user?.userProfile?.permissions;
                          if (this.permissions.length > 0) {
                            if( /\d+/.test(redirectURL)){
                              const urlParts = redirectURL.split('/');
                              urlParts.pop(); // Removes the last element
                              redirectURL = urlParts.join('/');
                            }
                            
                            let filteredPermissions = this.permissions.filter(x => x.pageRoute.includes(redirectURL));
                            if (filteredPermissions.length > 0) {
                              resolve(true);
                            } else {
                              console.log('Forbidden access1');
                              
                              this._router.navigateByUrl('/forbidden-access');
                              resolve(false);
                            }
                          } else {
                            console.log('Forbidden access2');
                            this._router.navigateByUrl('/forbidden-access');
                            resolve(false);
                          }
                        }, (error) => {
                          console.log('Forbidden access3');
                          this._router.navigateByUrl('/forbidden-access');
                          resolve(false);
                        });
                      });               
                })
            );
    }
}

