/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { isACEPUser, isBothACEPSicaUser, isSicaUser } from 'app/shared/utils';
import { NavigationMockApi } from './api';


// The small one
export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Accueil',
        type : 'basic',
        link : '/dashboard',
        roles: 'Admin',
        hidden: _ => !isSicaUser(),
    },
    {
        id   : 'prospects',
        title: 'Clients',
        type : 'basic',
        link : '/prospects',
        roles: 'Admin',
        hidden: _ => !isSicaUser(),
    },
    {
        id   : 'planning',
        title: 'Planning',
        type : 'basic',
        link : '/rdvs',
        roles: 'Admin',
        hidden: _ => !isSicaUser(),
    },
    {
        id   : 'map',
        title: 'Tournée',
        type : 'basic',
        link : '/map',
        roles: 'Admin',
        hidden: _ => !isSicaUser(),
    },
    {
        id   : 'partners',
        title: 'Financement',
        type : 'group',
        roles: 'Admin',
        hidden: _ => !isBothACEPSicaUser(),    
        children : [ 
            {
                id   : '1',
                title: 'Tableau de bord',
                type : 'basic',                
                link : '/partners/dashboard',
                function: _ => executeActionBeforeLeavingCustomerDetails('/partners/dashboard'),
                //hidden: _ => !hasPermission('partners/dashboard')
            },            
            {
                id   : '2',
                title: 'Dossier',
                type : 'basic',
                link : '/partners/drop-list',
                function: _ => executeActionBeforeLeavingCustomerDetails('/partners/drop-list'),
                //hidden: _ => !hasPermission('partners/drop-list')
            }, 
            {
                id   : '3',
                title: 'Facturation',
                type : 'basic',
                link : '/partners/invoices',
                function: _ => executeActionBeforeLeavingCustomerDetails('/partners/invoices'),
                //hidden: _ => !hasPermission('partners/invoices')
            },
            {
                id   : '4',
                title: 'Contrat',
                type : 'basic',                
                link : '/partners/drop',
                function: _ => executeActionBeforeLeavingCustomerDetails('/partners/drop'),
                //hidden: _ => !hasPermission('partners/drop')
            },
        ]
    },
    {
        id   : 'partners_dashboard',
        title: 'Accueil',
        type : 'basic',
        link : '/partners/dashboard',
        roles: 'Admin',
        hidden: _ => !isACEPUser() || (isACEPUser() && isSicaUser()),  
    },
    {
        id   : 'partners_foldrs',
        title: 'Dossiers',
        type : 'basic',
        link : '/partners/drop-list',
        roles: 'Admin',
        hidden: _ => !isACEPUser() || (isACEPUser() && isSicaUser()),  
    },
    {
        id   : 'partners_invoices',
        title: 'Facturation',
        type : 'basic',
        link : '/partners/invoices',
        roles: 'Admin',
        hidden: _ => !isACEPUser() || (isACEPUser() && isSicaUser()),   
    },
    {
        id   : 'partners_contracts',
        title: 'Contrats',
        type : 'basic',
        link : '/partners/contracts',
        roles: 'Admin',
        hidden: _ => !isACEPUser() || (isACEPUser() && isSicaUser()),    
    },
    {
        id   : 'settings',
        title: 'Paramètres',
        type : 'basic',
        link : '/settings/landing',
        roles: 'Admin',
        //hidden: _ => !isACEPUser() || (isACEPUser() && isSicaUser()),  
    }
];

export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Accueil',
        type : 'basic',
        link : '/dashboard',
        roles: 'Admin',
        hidden: _ => !isSicaUser(),
        function: _ => executeActionBeforeLeavingCustomerDetails('/dashboard')
    },
    {
        id   : 'prospects',
        title: 'Clients',
        type : 'basic',
        link : '/prospects',
        roles: 'Admin',
        hidden: _ => !isSicaUser(),
        function: _ => executeActionBeforeLeavingCustomerDetails('/prospects')
    },
    {
        id   : 'planning',
        title: 'Planning',
        type : 'basic',
        link : '/rdvs',
        roles: 'Admin',
        hidden: _ => !isSicaUser(),
        function: _ => executeActionBeforeLeavingCustomerDetails('/rdvs')
    },
    {
        id   : 'map',
        title: 'Tournée',
        type : 'basic',
        link : '/map',
        roles: 'Admin',
        hidden: _ => !isSicaUser(),
        function: _ => executeActionBeforeLeavingCustomerDetails('/map')
    },    
    {
        id   : 'partners',
        title: 'Financement',
        type : 'group',
        roles: 'Admin',
        hidden: _ => !isBothACEPSicaUser(),    
        children : [ 
            {
                id   : '1',
                title: 'Tableau de bord',
                type : 'basic',                
                link : '/partners/dashboard',
                function: _ => executeActionBeforeLeavingCustomerDetails('/partners/dashboard'),
                //hidden: _ => !hasPermission('partners/dashboard')
            },            
            {
                id   : '2',
                title: 'Dossier',
                type : 'basic',
                link : '/partners/drop-list',
                function: _ => executeActionBeforeLeavingCustomerDetails('/partners/drop-list'),
                //hidden: _ => !hasPermission('partners/drop-list')
            }, 
            {
                id   : '3',
                title: 'Facturation',
                type : 'basic',
                link : '/partners/invoices',
                function: _ => executeActionBeforeLeavingCustomerDetails('/partners/invoices'),
                //hidden: _ => !hasPermission('partners/invoices')
            },
            {
                id   : '4',
                title: 'Contrat',
                type : 'basic',                
                link : '/partners/drop',
                function: _ => executeActionBeforeLeavingCustomerDetails('/partners/drop'),
                //hidden: _ => !hasPermission('partners/drop')
            },
        ]
    },
    {
        id   : 'partners_dashboard',
        title: 'Accueil',
        type : 'basic',
        link : '/partners/dashboard',
        roles: 'Admin',
        hidden: _ => !isACEPUser() || (isACEPUser() && isSicaUser()),  
    },
    {
        id   : 'partners_foldrs',
        title: 'Dossiers',
        type : 'basic',
        link : '/partners/drop-list',
        roles: 'Admin',
        hidden: _ => !isACEPUser() || (isACEPUser() && isSicaUser()),  
    },
    {
        id   : 'partners_invoices',
        title: 'Facturation',
        type : 'basic',
        link : '/partners/invoices',
        roles: 'Admin',
        hidden: _ => !isACEPUser() || (isACEPUser() && isSicaUser()),  
    },
    {
        id   : 'partners_contracts',
        title: 'Contrats',
        type : 'basic',
        link : '/partners/contracts',
        roles: 'Admin',
        hidden: _ => !isACEPUser() || (isACEPUser() && isSicaUser()),  
    },
    {
        id   : 'settings',
        title: 'Paramètres',
        type : 'basic',
        link : '/settings/landing',
        roles: 'Admin',
        //hidden: _ =>!isACEPUser() && !isSicaUser(),   
    },
];
//isBothACEPSicaUser
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'Accueil',
        title: 'Accueil',
        type : 'basic',
        //icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];

export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'Accueil',
        title: 'Accueil',
        type : 'basic',
        //icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];

function executeActionBeforeLeavingCustomerDetails(uri): void {
    NavigationMockApi.executeActionBeforeLeavingCustomerDetails(uri);       
}

function hasPermission(redirectURL : string): boolean {
    if (NavigationMockApi.userPermissions?.length > 0) {
        let filtredPermissions = NavigationMockApi.userPermissions.filter(x => x.pageRoute == redirectURL);
        if (filtredPermissions.length > 0) {
            return true;
        } else {
            return false;
        }
        } else {     
        return false;
        }
}

function isAdminPartner() {
    return NavigationMockApi.userProfile.name == 'Admin E. Partenaire';
}
