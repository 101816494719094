import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { environment } from 'environments/environment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MomentUtcDateAdapter } from './shared/moment-utc-date-adapter';
import { ErrorCatchingInterceptor } from './shared/interceptors/error-catching.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { QuillModule } from 'ngx-quill';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { NotFoundErrorHandler } from './not-found-error-handler';
import { FuseAlertModule } from '@fuse/components/alert';
import { SignedDevisNotificationModule } from './shared/signed-notification/signed-notification.module';
import { ToastrModule } from 'ngx-toastr';
import { LicenseManager } from "ag-grid-enterprise";

registerLocaleData(localeFr);

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.DOMAIN
    },
    position: 'bottom-right',
    palette: {
        popup: {
            background: '#102639',
            text: '#fff'
        },
        button: {
            background: '#469278',
            text: "#fff"
        }
    },
    theme: 'block',
    type: 'info',
    layout: 'basic',
    // layout: 'my-custom-layout',
    // layouts: {
    //     "my-custom-layout": '{{messagelink}}{{compliance}}'
    // },
    elements: {
        messagelink: `
        <span id="cookieconsent:desc" class="cookies-popup-message">{{message}}</span>
        `,
    },
    content: {
        message: "Ce site web utilise des cookies pour vous assurer la meilleure expérience de navigation sur notre site.",
        dismiss: "OK, j'ai compris!",
        deny: "Refuser",
        header: "Cookies sur le site!",
        allow: "Autoriser les cookies"
    }
};

export const TooltipOptions: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchGestures: 'auto',
    touchendHideDelay: 0,
    disableTooltipInteractivity: true,
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        HttpClientModule,
        //Toast 
        ToastrModule.forRoot({
            timeOut: 15000, // 15 seconds
            closeButton: true,
            progressBar: true,
        }),
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseAlertModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),
        // Core module of your application
        CoreModule,
        // Layout module of your application
        LayoutModule,
        
        SignedDevisNotificationModule,
        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
        QuillModule.forRoot(),
        NgcCookieConsentModule.forRoot(cookieConfig)

    ],
    bootstrap: [
        AppComponent
    ],
    providers: [{
        provide: LOCALE_ID,
        useValue: 'fr-FR',
        
        
    },
    DecimalPipe,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorCatchingInterceptor,
        multi: true
    },
    { provide: ErrorHandler, useClass: NotFoundErrorHandler },
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: TooltipOptions}
    ]
})
export class AppModule {
    FullCalendarModule

    constructor() {
        LicenseManager.setLicenseKey("Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-071261}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{ACE_Energie}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{TERA}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{TERA}_need_to_be_licensed___{TERA}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{8_November_2025}____[v3]_[0102]_MTc2MjU2MDAwMDAwMA==ba4a4fd4caaf67dd62aaa9624f6bf9d1");
    }
}
